import {useEffect, useRef, useState} from "react";
import {LoadingSpinner} from "./loading/LoadingSpinner";
import Rosetta from "../rosetta/Rosetta";

export const RosettaAwareComponent = (props) => {

    const {loadingComponent} = props;
    const {callback} = props;

    const [rosettaReady, setRosettaReady] = useState(false);
    const [timerMutation, setTimerMutation] = useState(0);

    const readyTimer = useRef();

    useEffect(() => {
        const rosetta = Rosetta.getInstance();

        rosetta.addCallback((state) => {
            setRosettaReady(state === Rosetta.STATE_READY);
        })

        if (!rosettaReady) {
            // Have a timer running, checking to see if Rosetta is ready.
            readyTimer.current = setInterval(() => {
                setTimerMutation(Math.random());
            }, 200);
        }

        return () => {
            clearInterval(readyTimer.current);
        }
    }, []);

    useEffect(() => {
        if (rosettaReady) {
            if (callback) {
                callback();
            }

            clearInterval(readyTimer.current);
        }
    }, [rosettaReady]);

    useEffect(() => {
        if (rosettaReady || Rosetta.getInstance().getState() === Rosetta.STATE_READY) {
            clearInterval(readyTimer.current);
        }
    }, [timerMutation])

    let immediatelyReady = Rosetta.getInstance().getState() === Rosetta.STATE_READY;

    if (!rosettaReady && !immediatelyReady) {
        let loadingUI = <LoadingSpinner />;
        if (loadingComponent !== undefined) {
            loadingUI = loadingComponent;
        }
        return loadingUI;
    }

    return props.children;

}